@import '../../styles/variables';

.root {
  position: relative;
  display: inline-flex;
}

.base {
  display: inline-block;
  position: relative;
  padding: 0 0.75em;
  padding-right: 2.5rem;
  flex-grow: 1;
  border-radius: 0.1rem;
  line-height: 2.5;
  border: 0.1rem solid $grey;
  min-height: 2.5em;
  width: 20rem;
  user-select: none;
  cursor: pointer;

  &:hover {
    border: 0.1rem solid $darkGrey;
  }
}

.singleBase,
.noItems {
  &::after {
    content: '';
    right: 0.75rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    transform: rotate(0deg);
    transition: transform .3s ease-in-out;
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/></svg>');
  }
}

.search {
  outline: 0;
  box-shadow: none;
  border: 0;
  min-width: 1rem;
  min-height: 2.5rem;
  display: inline-block;
  margin: 0 0.5rem;
  line-height: 1;
  white-space: nowrap;
  vertical-align: bottom;
}

.noItems .search:empty::before {
  content: attr(placeholder);
  color: #858585;
}

.picker {
  overflow: hidden;
  min-width: 100%;
  position: absolute;
  display: inline-block;
  top: calc(100% + .5rem);
  left: 0;
  max-height: 14em;
  overflow-y: auto;
  border-radius: 0.2rem;
  background: #fff;
  box-shadow: 0 0.1rem 1rem 0.05rem rgba(0, 0, 0, 0.15);
  transform-origin: top;
  animation: selectReveal .2s ease-in-out;
  animation-fill-mode: both;
  animation-play-state: running;
  z-index: $zIndexSuper;

  &:empty::before {
    content: 'No results';
    display: block;
    padding: 0.75em 1em;
    font-style: italic;
    color: $darkGrey;
  }

  & > div {
    cursor: pointer;
    padding: 0.75rem 1rem;
    padding-right: 3rem;
    position: relative;
    line-height: 1.5;

    &:hover {
      background: $lightGrey;
    }
  }
}

.selected {
  &::after {
    content: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 26 26"><path fill="green" d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"/></svg>');
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
  }
}

.highlighted {
  background: $grey !important;
}

.open {
  .base {
    outline: 0;
    border-color: $primaryColor;
    box-shadow: 0 0 0.2rem $primaryColor;

    &::after {
      transform: rotate(180deg);
    }
  }
}

.minimal {
  .base {
    font-size: 1.2rem;
    border: 0;
    width: 17rem;
  }

  .picker * {
    font-size: 1.2rem;
  }
}

.label {
  display: inline-block;
  border-radius: 0.3rem;
  line-height: 1;
  padding: 0.2rem;
  margin-right: 0.5em;
  border: 0.1rem solid $grey;
  background: $lightGrey;
  font-size: 0.9em;
  transition: background .2s;

  &:hover {
    background: darken($lightGrey, 5%);
  }
  // &:after {
  //   content: 'x';
  //   cursor: pointer;
  //   color: #000;
  //   display: inline-block;
  //   font-size: 1rem;
  //   vertical-align: middle;
  //   margin-left: .4rem;
  // }
}

.loading {
  @extend .singleBase;

  &::after {
    width: 2em;
    height: 2em;
    right: 0.25rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-ripple" style="background: none;"><circle cx="50" cy="50" r="23.3941" fill="none" stroke-width="3"><animate attributeName="r" calcMode="spline" values="0;30" keyTimes="0;1" dur="1.6" keySplines="0 0.2 0.8 1" begin="-0.8s" repeatCount="indefinite"></animate><animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1.6" keySplines="0.2 0 0.8 1" begin="-0.8s" repeatCount="indefinite"></animate></circle><circle cx="50" cy="50" r="7.96641" fill="none" stroke-width="3"><animate attributeName="r" calcMode="spline" values="0;30" keyTimes="0;1" dur="1.6" keySplines="0 0.2 0.8 1" begin="0s" repeatCount="indefinite"></animate><animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1.6" keySplines="0.2 0 0.8 1" begin="0s" repeatCount="indefinite"></animate></circle></svg>');
  }
}

.disabled {
  background: $lightGrey;
  color: $grey;
  cursor: not-allowed;
}

@keyframes selectReveal {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}
