@import '../../styles/variables';

.overlay {
  background: rgba(#000,0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  overflow-y: auto;
  animation: modalShow .3s ease-out;
  animation-fill-mode: both;
  animation-play-state: running;
}

.root {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  z-index: $zIndexHigh;
  width: 60rem;
  max-width: 90%;
  overflow-y: auto;
}

.inner {
  padding: 3rem;
  height: 100%;
}

.close {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 1rem;
  right: 1.2rem;
  font-size: 1.7rem;
  color: $darkGrey;
}

.actions {
  display: flex;
  > * {
    flex-grow: 1;
  }
}

@keyframes modalShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
