@import '../../styles/variables';

.root {
  position: relative;
  z-index: 2;
  width: 100%;
  background: $warningOrange;
  font-weight: normal;
  color: #333;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-play-state: running;
  animation-iteration-count: 1;
}

.error {
  background: $errorRed;
  color: #fff;
  font-weight: normal;
}

.spinner {
  width: 1.2em;
  height: 1.2em;
  margin-right: 1rem;
}

@keyframes slideIn {
  0% {
    transform: translateY(-110%);
  }

  100% {
    transform: translateY(0);
  }
}
