$height: 3.5rem;

.root {
  margin-bottom: 3rem;
  display: flex;
}

.createButton {
  margin-left: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: 0 !important;
  height: $height;
  padding: 1rem;
}

.popupButton {
  padding: 1rem;
  outline: 0 !important;
  height: $height;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
