.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  height: 100%;
}

.full {
  flex-basis: 100%;

  > * {
    width: 100%;
  }
}

.half {
  flex-basis: calc(50% - 1rem);

  > * {
    width: 100%;
  }
}

.heading {
  margin-bottom: 5rem;
  font-weight: 900;
}

.headerIcon {
  display: inline-block;
  width: auto;
  font-size: 1.05em;
  transform: translateY(0.15em);
  margin-right: 2rem;
}

.button {
  margin-left: auto;
  margin-top: 5rem;

  &:last-child {
    margin-left: 1.5rem;
  }
}
