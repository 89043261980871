$primaryColor: #502d63;
$secondaryColor: #3b6e83;
$darkGrey: #676767;
$grey: #f1f1f1;
$lightGrey: #fafafa;

$errorRed: #da3c11;
$cautionYellow: #d8b012;
$warningOrange: #f1960c;
$successGreen: #0ca915;

$siteWidth: 90rem;

$zIndexBump: 10;
$zIndexHigh: 20;
$zIndexSuper: 30;
