
@import '../../styles/variables';

.root {
  background: $primaryColor;
  width: 100%;
  box-shadow: 0.5rem 0 2rem rgba(0, 0, 0, 0.25);
}

.wrapper {
  display: flex;
  max-width: $siteWidth;
  margin: 0 auto;
}

.envPicker {
  margin-left: auto;
  margin-right: 3rem;
  background: rgba(255, 255, 255, 0.9);
  align-self: center;
}

.logo {
  display: block;
  font-weight: bold;
  font-size: 3rem;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  padding-left: 3rem;
  line-height: 5.25rem;
  color: #fff;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
  flex: 1 0 auto;
  display: flex;
  color: #fff;
}

.activeLink svg {
  opacity: 1 !important;
}

.link {
  display: flex;
  padding: 2rem 3rem;
  letter-spacing: 0.02em;
  margin: 0;
  position: relative;
  cursor: pointer;

  & > svg {
    opacity: 0.6;
    font-size: 1.8rem;
    display: inline-block;
    transform: translateY(-0.1em);
  }

  & > span {
    padding-left: 1.5rem;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.01em;
  }

  // &:last-child {
  //   margin-left: auto;
  //   justify-self: flex-end;
  // }

  &:hover {
    background: lighten($primaryColor, 2.5%);

    > svg {
      opacity: 1;
    }
  }
}

