@import '../styles/variables';

.content {
  margin: 0 auto;
  max-width: $siteWidth;
  padding: 3rem 3rem;
  min-height: calc(100vh - 4rem);
}

.footer {
  font-size: 1.2rem;
  color: $darkGrey;
  text-align: center;
  line-height: 4rem;
  height: 4rem;
}
