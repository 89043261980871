$height: 3.5rem;

.buttons {
  display: flex;
  float: right;
}

.popupButton {
  padding: 1rem;
  outline: 0 !important;
  height: $height;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.editButton {
  padding: 1rem;
  outline: 0 !important;
  height: $height;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.openLink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
